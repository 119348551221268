(function($){

    function c37RenderBackgroundVideo()
    {
        var hasVideoBgElements = $('.c37-has-yt-bg');
        _.each(hasVideoBgElements, function(e) {
            var outerElementWidth = $(e).outerWidth();
            var outerElementHeight = $(e).outerHeight();


            var videoBgWidth = 0;
            var videoBgHeight = 0;
            //if width/height > 16/9, width of the bg = the width of the outer element, height of the bg = width * 9/16
            if (outerElementWidth/outerElementHeight >= 16/9)
            {
                videoBgWidth = outerElementWidth;
                videoBgHeight = outerElementWidth * 9/16;

            } //if (width/height) < 16/9 (the element is more similar to a square), video height = outerElementHeight, video width = outerElementHeight * 16/9
            else {

                videoBgWidth = outerElementHeight * 16/9;
                videoBgHeight = outerElementHeight;
            }

            var bgElement = $(e).find('.c37-yt-bg iframe');
            bgElement.outerHeight(videoBgHeight);
            bgElement.outerWidth(videoBgWidth);

            //center the video element
            bgElement.css('margin-left', ( outerElementWidth - videoBgWidth ) / 2);
            bgElement.css('margin-top', ( outerElementHeight - videoBgHeight ) / 2);


            var overlayElement = $(e).find('.c37-video-bg-overlay');
            overlayElement.outerHeight(videoBgHeight);
            overlayElement.outerWidth(videoBgWidth);

        });
    }

    $(function(){
        c37RenderBackgroundVideo();
        $(window).on('resize', c37RenderBackgroundVideo);
    });

})(jQuery);