/**
 * Created by luis on 19/07/17.
 */

/**
 * How does tracking work
 * 1. On squeeze page
 * On page load, one session ID will be created, the tracking script will send this
 *
 * 2. On popup
 * On appear, create a session ID associated with the popup, use this session to track further
 * actions
 *
 * 3. On widget
 * On element visible, send hit with session ID
 */

function WPLX_TRACKING()
{
    var obj = {};

    obj.pageOpen = function (page_id, page_variant_id, session_id)
    {

        console.log('WPLX_PAGE: ','send hit on page open');
        this.sendHit(
            page_id,
            page_variant_id,
            0,//page has no option id, this is for popup only
            'page',
            'open', 'page first open',
            session_id,
            page_id,
            page_id,
            window.location.href

            );
    };

    obj.popupOpen = function (page_id, page_variant_id, popup_option_id, session_id)
    {
        console.log('WPLX_POPUP: ','send hit on popup open');
        this.sendHit(
            page_id,
            page_variant_id,
            popup_option_id,
            'popup',
            'open',
            'popup_open',
            session_id,
            popup_option_id,
            popup_option_id,
            window.location.href
            );

    };


    obj.popupClose = function (page_id, page_variant_id, popup_option_id, session_id)
    {

        console.log('WPLX_POPUP: ','send hit on popup close');
        this.sendHit(
            page_id,
            page_variant_id,
            popup_option_id,
            'popup',
            'close',
            'popup_open',
            session_id,
            popup_option_id,
            popup_option_id,
            window.location.href
        );

    };


    /**
     *
     * @param page_id
     * @param session_id
     * @param page_variant_id
     */
    obj.pageClose = function (page_id, page_variant_id, session_id)
    {

        console.log('WPLX_PAGE: ','send hit on page close');
        this.sendHit(
            page_id,
            page_variant_id,
            0,
            'page',
            'close',
            'page close',
            session_id,
            page_id,
            page_id,
            window.location.href

        );
    };


    /**
     * get the session id from HTML
     * @param element jQuery element
     * @param element_type page/popup/element
     * @return string|null a string or null
     */
    obj.getSessionID = function (element, element_type)
    {
        var session_id = null;
        switch (element_type)
        {
            case 'page':
                session_id = element.find('[data-tracking-session-id]').first().attr('data-tracking-session-id');
                break;
            case 'popup':
                session_id = element.find('[data-tracking-session-id]').first().attr('data-tracking-session-id');
                break;
            case 'element':
                session_id = element.closest('[data-tracking-session-id]').attr('data-tracking-session-id');
                break;

            default:
                break;

        }

        console.log('session id: ', session_id);
        return session_id;
    };


    obj.sendHit = function(page_id, page_variant_id, option_id, page_type, event_name, event_friendly_name, session_id, received_element, received_element_tag, url)
    {
        var data = {
            page_id: page_id,
            page_variant_id: page_variant_id,
            option_id: option_id,
            page_type: page_type,

            event_name: event_name,
            event_friendly_name: event_name,
            session_id: session_id,
            received_element: received_element,
            received_element_tag: received_element_tag,
            url: url,
            action: 'wplx_save_hit'
        };

        console.log('tracking data: ', data);
        jQuery.post(ajaxurl, data , function(response) {
            console.log(response);
        });
    };

    /**
     *
     * @param element
     * @param page_type
     */
    obj.getParentData = function(element, page_type)
    {
        var page_id = 0;
        var page_variant_id = 0;
        var option_id = 0;

        //getting page_id, page_variant_id, option_id...
        if (page_type === 'popup')
        {
            option_id = element.closest('[data-popup-option-id]').length > 0 ? element.closest('[data-popup-option-id]').attr('data-popup-option-id') : 0;
            page_id = element.closest('[data-tracking-parent-id]').length > 0 ? element.closest('[data-tracking-parent-id]').attr('data-tracking-parent-id') : 0;
            page_variant_id = element.closest('[data-tracking-variant-id]').length > 0 ? element.closest('[data-tracking-variant-id]').attr('data-tracking-variant-id') : 0;

        } else if (page_type === 'page')
        {
            page_id = element.closest('[data-tracking-parent-id]').length > 0 ? element.closest('[data-tracking-parent-id]').attr('data-tracking-parent-id') : 0;
            page_variant_id = element.closest('[data-tracking-variant-id]').length > 0 ? element.closest('[data-tracking-variant-id]').attr('data-tracking-variant-id') : 0;

        }

        return {
            option_id: option_id,
            page_id: page_id,
            page_variant_id: page_variant_id
        }
    };

    return obj;

}

var wplx_tracking = wplx_tracking || WPLX_TRACKING();
