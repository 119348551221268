/**
 * Created by luis on 11/7/16.
 */
(function(){

    var showedPopupInCurrentSession = [];
    /**
     * from an popup option, check if the popup can be shown,
     * the decision is made based on option value of after close
     * @param popupOption
     * @param popupOptionID
     */
    function canShow(popupOptionID, popupOption)
    {

        var popup = jQuery('#' + popupOption.popupID);

        if (popupOption.afterClose.action === 'never_show' && isPopupShowedInLifeTime(popupOptionID))
        {
            console.log('popup not showing because it was set to be shown once');
            return false;
        }

        if (_.contains(showedPopupInCurrentSession, popupOptionID))
        {
            console.log('popup not showing because it was shown in current session');
            return false;
        }

        //if the popup was shown, however, it hasn't passed the hibernate period, don't show again
        if (popupOption.afterClose.action==='hide_x_day')
        {
            if (getTimePopupLastShown(popupOptionID) !== null)
            {
                var today = new Date();
                var timeDiff = Math.abs(today.getTime() - getTimePopupLastShown(popupOptionID).getTime());
                console.log("time diff is: ", timeDiff);
                var diffDays = Math.round(timeDiff / (1000 * 3600 * 24));
                console.log("Days difference is: ", diffDays);

                if (diffDays < popupOption.afterClose.daysToHide)
                    return false;
            }
        }

        //1. if the popup has shown in current session, don't show it again

        //in case
        return true;
    }

    /**
     * get the value of last_showed of popup in the localstorage object, return null if not available
     * @param popupOptionID
     */
    function getTimePopupLastShown(popupOptionID)
    {
        var objectList = getWPLXShownPopups();
        if (typeof objectList[popupOptionID] === 'undefined')
            return null;
        if (typeof objectList[popupOptionID].last_showed === 'undefined')
            return null;

        //last_showed is the string value of the last time the popup was showed
        return new Date(objectList[popupOptionID].last_showed);

    }

    /**
     * get the object of shown popup stored in localstorage
     * @returns {any}
     */
    function getWPLXShownPopups()
    {
        return JSON.parse(localStorage.getItem('wplx_list_shown_popups') || "{}");
    }

    function isPopupShowedInLifeTime(popupOptionID)
    {
        var recordedOptions = getWPLXShownPopups();
        console.log("recorded option", recordedOptions);
        console.log("popup id: ", popupOptionID);
        return  (typeof recordedOptions[popupOptionID] !== 'undefined');
    }

    /**
     * record popups options that showed in this format:
     * {
     *  popup_option_id :
     *  {
     *      last_showed: 'time that popup last showed'
     *  }
     * }
     * @param popupOptionID
     */
    function recordPopupShowed(popupOptionID)
    {
        var string = localStorage.getItem('wplx_list_shown_popups') || "{}";
        var recordedOptions =  [];

        try {
            recordedOptions = JSON.parse(string);
        } catch (err)
        {
            console.error(err);
            localStorage.setItem('wplx_list_shown_popups', '{}');
        }


        showedPopupInCurrentSession.push(popupOptionID);
        console.log('list of popup showen in current sessino' , showedPopupInCurrentSession);

        recordedOptions[popupOptionID] = { 'last_showed' : (new Date()).toISOString() };
        localStorage.setItem('wplx_list_shown_popups', JSON.stringify(recordedOptions));

    }

    jQuery(function(){
        /**
         * currentSessionPopupShowed is set to false on every session (page, visit)
         * It prevents the popup from showing up multiple time in one page load
         */

        function showPopup(popup, displayOption)
        {
            var popupOptionID = popup.attr('data-popup-option-id');

            if (!canShow(popupOptionID, displayOption))
            {

                return;
            } else
            {
                console.log("we can show it: ", popupOptionID, " and the display option: ", displayOption);
            }

            console.log("popup option is: ", displayOption);


            //send hit event
            // c37TrackingPopupOpen(popupOptionID, sessionID, 'popup');

            recordPopupShowed(popupOptionID);



            let completeClass = '';


            switch(displayOption.positionOnPage)
            {

                case 'top_left':

                    completeClass = 'c37-popup-top-left';
                    break;
                case 'top_right':

                    completeClass = 'c37-popup-top-right';
                    break;
                case 'bottom_left':
                    completeClass = 'c37-popup-bottom-left';
                    break;
                case 'bottom_right':
                    completeClass = 'c37-popup-bottom-right';
                    break;
                case 'center':
                    completeClass = 'c37-popup-center';
                    break;
                case 'center-top':
                    completeClass = 'c37-popup-center-top';
                    break;
                case 'center-bottom':
                    completeClass = 'c37-popup-center-bottom';
                    break;

                case 'center-left':
                    completeClass = 'c37-popup-center-left';
                    break;

                case 'center-right':
                    completeClass = 'c37-popup-center-right';
                    break;

                default:
                    completeClass = 'c37-popup-center';
                    break;
            }




            /* DISABLE ALL ANIMATIONS FOR NOW */
            var outerMostPopup = popup.closest('.wplx-outermost-popup-container');
            popup.removeClass('c37-d-none');
            outerMostPopup.removeClass('c37-d-none');

            outerMostPopup.css('display', 'block');
            popup.show('', {'animation' : 'slide'});

            var popup_data = wplx_get_popup_data(popup);
            // console.log('popup data is')
            wplx_tracking.popupOpen(
                popup_data.page_id,
                popup_data.page_variant_id,
                popup_data.option_id,
                popup_data.session_id
            );



        }


        function wplx_get_popup_data(popup)
        {
            var wplx_tracking = wplx_tracking || WPLX_TRACKING();
            var data_div = popup.find('[data-tracking-session-id]').first();//find the div that contains data
            var page_id = data_div.attr('data-tracking-parent-id');
            var page_variant_id = data_div.attr('data-tracking-variant-id');
            var option_id = popup.attr('data-popup-option-id');
            var session_id = wplx_tracking.getSessionID(popup, 'popup');

            return {
                page_id: page_id,
                page_variant_id: page_variant_id || 0,
                option_id: option_id,
                session_id: session_id
            }

        }

        jQuery(document).on('click', '.c37-lp-close-popup', function(){

            var popup = jQuery(this).closest('.wplx-outermost-popup-container');

            var popup_div_that_contains_data =  popup.find('[data-popup-option-id]').first();
            var popup_data = wplx_get_popup_data(popup_div_that_contains_data);
            wplx_tracking.popupClose(
                popup_data.page_id,
                popup_data.page_variant_id,
                popup_data.option_id,
                popup_data.session_id
            );
            popup.remove();
        });

        //the howToShowUp settings is printed when create the popup (c37-popup-manager)
        if (typeof howToShowUp !== 'undefined')
        {
            var display = howToShowUp;

            _.each(display, function(d){
                var popup = jQuery('#'+d.popupID);

                console.log('popup css id is: ', d.popupID);


                if (d.hideMobile && d.hideDesktop)
                {
                    console.info("popup was set to hide on mobile and desktop");
                    return;
                }
                else if (d.hideMobile)
                {
                    console.info('popup was set to hide on mobile')
                    if (c37isMobile)
                        return;
                } else if (d.hideDesktop)
                {
                    console.info('popup was set to hide on Desktop')
                    if (!c37isMobile)
                        return;
                }

                if (d.howToShowUp.trigger === 'mouse_exits')
                {
                    jQuery(document).on('mouseleave', function(e){
                        console.log(e.clientY);
                        if (e.clientY < 100)
                        {
                            console.log('showing popup on mouse leave, exit intent');
                            showPopup(popup, d);
                            jQuery(window).trigger('resize');
                        }

                    });
                } else if (d.howToShowUp.trigger === 'after_page_load')
                {

                    setTimeout(function(){
                        console.log('showing popup on page load');
                        showPopup(popup, d);
                        //send a fake resize event so the background can update and fill the whole page
                        jQuery(window).trigger('resize');
                    }, d.howToShowUp.delay * 1000);
                } else if (d.howToShowUp.trigger === 'vertical_scroll')
                {
                    console.log('popup is set to show on vertical scroll');
                    //don't show the popup if the trigger is undefined
                    var scrollObject = d.howToShowUp.scroll;
                    if (typeof scrollObject.trigger === 'undefined')
                    {
                        console.error('trigger is undefined!');
                        return;
                    }

                    //if user set the trigger to scroll to a specific pixel, create a
                    //scroll listener and trigger the popup
                    if (scrollObject.trigger === 'pixel')
                    {
                        var scrollListener = typeof scrollObject.scrollEventListener === "undefined" || scrollObject.scrollEventListener === "" ? window : scrollObject.scrollEventListener;

                        if (scrollListener === "document")
                            scrollListener = document;

                        console.log(scrollObject);

                        console.log('Scroll listener is: ' + scrollListener);

                        jQuery(scrollListener).on('scroll', function(){

                            var distanceFromTop;
                            //in case the use set an element for scrolling, use of offset of that element
                            //to trigger

                            if (typeof scrollObject.element !== "undefined" && scrollObject.element !=="")
                            {
                                distanceFromTop = Math.abs(jQuery(scrollObject.element).offset().top);
                            } else
                            {
                                distanceFromTop = jQuery('body').scrollTop();
                            }

                            console.log('distance from top is: ' + distanceFromTop + 'px');

                            if (distanceFromTop >= scrollObject.pixel)
                            {
                                console.log('showing popup on scroll');
                                showPopup(popup, d);
                            }

                        });

                    }

                }

            });
        }

    });

})();