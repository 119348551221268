/**
 * Created by luis on 9/4/16.
 */
//define actions responses for users' interactions
//to handle click
(function($){ 

    //handle action on element click
    /**
     * The key to handle click is this. The elementsActions object registered the action for
     * landing page -> container element (.c37-item-element). When then c37-child is clicked, it will
     * find in the elementsAction the object has the name by .c37-item-element ID
     * Illustration can be found here: https://drive.google.com/open?id=0B1dTAFXQBPD2b29oWWFYa0dKQW8
     */
    $(document).on('click', '.c37-lp .c37-lp-element', function(){



        var element = $(this);
        var tracking_name = element.attr('data-tracking-name') || '';

        var page_type = element.closest('.c37-page-container').length > 0 ? 'page' : (element.closest('.wplx-outermost-popup-container').length > 0 ? 'popup' : 'widget');

        var session_id = wplx_tracking.getSessionID(element, 'element');

        var parent_data = wplx_tracking.getParentData(element, page_type);

        var element_type = element.attr('data-c37-type');

        if (element_type === 'button' || tracking_name !== '')
        {

            console.log('sending hit for elements');
            //only track hit on buttons and elements that has tracking names
            wplx_tracking.sendHit(
                parent_data.page_id, parent_data.page_variant_id, parent_data.option_id, page_type, 'click', 'click', session_id, element.attr('id'), tracking_name, window.location.href
            );
        }


        if (typeof elementsActions === 'undefined')
        {
            console.log(' action object is nulled; ');
            return;
        }

        console.log(' action object is NOT null ');

        var pageUUID = $(this).closest('.c37-page-wrapper').attr('data-c37-uuid');

        //parentID is the ID of the container (which has the class .c37-lp-element)
        var parentID = $(this).closest('.c37-lp-element').attr('id');


        //elementActions is the global object that contains all Actions of all pages (for cases when there are multiple
        //pages/widget/popup available in one URL)
        var pageElementOptions = elementsActions[pageUUID];

        if (!_.isEmpty(pageElementOptions))
        {
            //should have used a temporary ID for this
            var elementAction = pageElementOptions[parentID];

            if (typeof elementAction !== "object")
            {
                console.log('element action is nulled');
                return;
            }

            _.each(elementAction, function(singleAction){
                if (singleAction.action === 'open-link')
                {
                    console.log('open link');
                    if (singleAction['new-tab'])
                    {
                        window.open(singleAction.target);
                    } else
                    {
                        window.location.href = singleAction.target;
                    }

                } else if (singleAction.action === 'submit-form')
                {
                    /*
                     | Submit current form
                     */
                    $(this).closest('form').submit();

                } else if (singleAction.action === 'open-popup')
                {

                    console.log('open popup again' );
                    /**
                     * c37PopupTrigger is an object set in c37-popup-manager.php
                     *
                     */
                    if (typeof c37PopupTrigger !== 'undefined')
                    {
                        //need to add tracking here for popup open and close

                        var id = '#'+c37PopupTrigger[singleAction['element-id']];
                        console.log('open the popup with ID: ', id);
                        $(id).removeClass('c37-d-none');
                        $(id).show();

                    } else
                    {
                        console.log('c37 popup trigger is undefined')
                    }
                } else if(singleAction.action === 'close-popup')
                {
                    //close the popup
                    console.log('id is: ', '#' + singleAction['element-id']);
                    $('#' + singleAction['element-id']).closest('.wplx-outermost-popup-container').remove();
                } else if (singleAction.action === 'show-elements')
                {
                    var elementsToShow = singleAction.target;
                    $(elementsToShow).removeClass('c37-d-none');


                } else if (singleAction.action === 'hide-elements')
                {
                    var elementsToHide = singleAction.target;
                    $(elementsToHide).addClass('c37-d-none');
                }
            });


        }



    });


    $(document).on('submit','[data-c37-type=form_container] form', function(e){
        //intercept the form submit and check if the user forces it to submit via ajax

       var form = $(this);

       var submit_via_ajax = form.attr('data-via-ajax') === 'true';
       if (submit_via_ajax)
       {
           e.preventDefault();
           var message = decodeURIComponent($(this).attr('data-ajax-message'));
            var action_url = form.attr('action');
            var method = form.attr('method') === 'get' ? 'GET' : 'POST';
           var data = {};

           //get data from checkbox first
           _.each(form.find('input[type=checkbox]'), function(checkbox){
               var element = $(checkbox);
               if (element.is(":checked"))
               {
                   var element_name = element.attr('name').replace('[', '').replace(']', '');
                   data[element_name] = data[element_name] || [];

                   data[element_name].push(element.val());
               }
           });



           _.each(form.find('input[type=radio]'), function(radio){
                var radio_element = $(radio);
                if (radio_element.is(':checked'))
                    data[radio_element.attr('name')] = radio_element.val();
           });


           _.each(form.find('input:not([type=checkbox]):not([type=radio]), select, textarea'), function(input_element){
               var element = $(input_element);

               if (element.attr('name') !== '' && element.attr('name') !== undefined)
               {
                   data[element.attr('name')] = element.val();
               }

           });

           //data is ready to send

           $.ajax({
               url: action_url,
               context: document.body,
               method: method,
               data: data

           }).done(function(){
               if (message !== '')
                   form.append('<p>'+message+'</p>');

           });


       }
    });
})(jQuery);

