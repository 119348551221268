/**
 * Created by luis on 15/06/17.
 */
jQuery(function () {
    jQuery('.menu-toggle').on('click', function(){
        jQuery('.c37-icon-one').toggleClass('active-one');
        //find the right menu
        var menuItems =  jQuery(this).closest('.c37-lp-menu').find('.c37-menu-right-content').first();
        var menuLeftContent = jQuery('.c37-left-content');
        menuItems.toggleClass('c37-menu-mobile-visible');
    });
});